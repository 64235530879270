import React, { useMemo, useState, Fragment } from "react";
import DataTable from "react-data-table-component";

const RekapPendudukTable = props => {
	const { list_kecamatan, list_desa } = props.resultData;

	const [selectedKec, setSelectedKec] = useState("");
	const [selectedDesa, setSelectedDesa] = useState("");
	const [query, setQuery] = useState("");

	const listDeskel = useMemo(() => {
		setSelectedDesa("");
		return list_desa.filter(desa => {
			let kode_kec = `${desa.k1}.${desa.k2}.${desa.k3}`;
			return kode_kec === selectedKec;
		});
	}, [list_desa, selectedKec]);

	const listKec = useMemo(() => {
		return list_kecamatan;
	}, [list_kecamatan]);

	const rows = useMemo(() => {
		const deskel = list_desa.filter(desa => {
			if (query !== "") {
				if (desa.nama_deskel.toLowerCase().indexOf(query.toLowerCase()) > -1) {
					return true;
				} else {
					return false;
				}
			}
			if (selectedKec && selectedDesa) {
				return desa.kode_wilayah === selectedDesa;
			} else if (selectedKec) {
				let kode_kec = `${desa.k1}.${desa.k2
					.toString()
					.padStart(2, "0")}.${desa.k3.toString().padStart(2, "0")}`;
				return kode_kec === selectedKec;
			} else {
				return true;
			}
		});

		return deskel;
	}, [selectedKec, selectedDesa, query, listDeskel]);

	const customStyles = {
		headCells: {
			style: {
				fontSize: "14px",
				fontWeight: "bold",
				backgroundColor: "#F1ECFF",
				borderRight: "1px solid #EDEDED",
				borderTop: "1px solid #EDEDED",
			},
		},
		cells: {
			style: {
				fontSize: "14px",
				borderRight: "1px solid #EDEDED",
			},
		},
	};

	return (
		<Fragment>
			<div className="row g-1 mb-4">
				<div className="col-3">
					<div className="search-produk">
						<form className="search-form-produk d-flex align-items-center">
							<input
								value={query}
								onChange={e => setQuery(e.target.value)}
								type="text"
								name="query"
								placeholder="Cari Desa/Kelurahan..."
								title="Enter search keyword"
							/>
							<button type="submit" title="Search" disabled>
								<i className="bi bi-search"></i>
							</button>
						</form>
					</div>
				</div>
				<div className="col-3">
					<select
						onChange={e => setSelectedKec(e.target.value)}
						className="form-select"
						aria-label="Default select example"
					>
						<option value={""}>Semua Kecamatan</option>
						{listKec.map(item => {
							return (
								<option
									key={item.kode_wilayah}
									value={item.kode_wilayah}
									selected={selectedKec === item.kode_wilayah}
								>
									{item.nama_kecamatan}
								</option>
							);
						})}
					</select>
				</div>
				<div className="col-3">
					<select
						onChange={e => setSelectedDesa(e.target.value)}
						className="form-select"
						aria-label="Default select example"
					>
						<option value={""}>Semua Desa</option>
						{listDeskel.map(item => {
							return (
								<option
									key={item.kode_wilayah}
									value={item.kode_wilayah}
									selected={selectedDesa === item.kode_wilayah}
								>
									{item.nama_deskel}
								</option>
							);
						})}
					</select>
				</div>
			</div>
			<DataTable
				columns={[
					{
						name: "No",
						selector: (row, index) => index + 1,
						width: "60px",
						style: {
							borderLeft: "1px solid #EDEDED",
						},
					},
					{
						name: "Kecamatan",
						sortable: true,
						selector: row => row.nama_kecamatan,
						width: "180px",
						wrap: true,
					},
					{
						name: "Desa",
						sortable: true,
						selector: row => row.nama_deskel,
						width: "180px",
						wrap: true,
					},
					{
						name: "Laki-Laki",
						sortable: true,
						selector: row => row.pria,
						width: "180px",
						wrap: true,
					},
					{
						name: "Perempuan",
						sortable: true,
						selector: row => row.wanita,
						width: "180px",
						wrap: true,
					},
					{
						name: "Jumlah Penduduk",
						sortable: true,
						selector: row => row.jumlah_penduduk,
						width: "180px",
						wrap: true,
					},
					{
						name: "Jumlah KK",
						sortable: true,
						selector: row => row.jumlah_kk,
						width: "180px",
						wrap: true,
					},
					{
						name: "Wajib KTP",
						sortable: true,
						selector: row => row.jml_wktp,
						width: "180px",
						wrap: true,
					},
				]}
				data={rows}
				customStyles={customStyles}
				pagination
			/>
		</Fragment>
	);
};

export default RekapPendudukTable;
